* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.homepage-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1rem;
	color: #ffffff;

	h1 {
		font-size: 2rem;
		margin-bottom: 1rem;
		text-align: left;
		color: #f0f6fc;
	}

	p {
		font-size: 1.2rem;
		text-align: left;
		color: #f0f6fc;
	}
}

.event-card {
	background-color: transparent !important;
	border: none;
	border-radius: 8px;
	overflow: hidden;
	height: 100%;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

	&:hover {
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
		transform: translateY(-2px);
		transition: all 0.2s ease-in-out;
	}

	.event-image {
		width: 100%;
		height: 200px;
		object-fit: cover;
		border-radius: 8px 8px 0 0;
	}

	.card-body {
		padding: 1rem;
		background-color: transparent !important;
		text-align: left;

		.event-meta {
			font-size: 0.9rem;
			color: #f0f6fc;
			margin-bottom: 0.5rem;
			text-align: left;
		}

		.event-title {
			font-size: 1.2rem;
			font-weight: bold;
			margin-bottom: 0.5rem;
			color: #f0f6fc;
			text-align: left;
		}

		.event-description {
			font-size: 1rem;
			color: #f0f6fc;
			margin-bottom: 0.5rem;
			text-align: left;
		}

		.event-ages {
			font-size: 0.9rem;
			color: #f0f6fc;
			text-align: left;
		}
	}
}

.event-link {
	text-decoration: none;
	color: inherit;
	display: block;
	height: 100%;
}
